import { graphql } from 'gatsby';
import * as React from 'react';
import Gallery from 'react-photo-gallery';

import Layout from '../../components/Layout';
import { Main } from '../../components/molecules/Main';

const PortfolioWeddingPage = ({
  data: {
    allFile: { edges },
    // site: {
    //   siteMetadata: { title },
    // },
  },
}) => {
  console.log(edges);

  const photos = edges.map((p) => ({
    src: p.node.childImageSharp.fluid.src,
    width: p.node.childImageSharp.fluid.presentationWidth,
    height: p.node.childImageSharp.fluid.presentationHeight,
    sizes: p.node.childImageSharp.fluid.sizes,
    srcSet: p.node.childImageSharp.fluid.srcSetWebp,
    alt: p.alt ?? '',
    title: p.alt ?? '',
  }));

  return (
    <Layout>
      <Main withVerticalSpacing fillHeight>
        <Gallery photos={photos} direction="column" />;
      </Main>
    </Layout>
  );
};

export default PortfolioWeddingPage;

export const portfolioWeddingPageQuery = graphql`
  query PortfolioWeddingQuery {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "wedding" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
              presentationWidth
              presentationHeight
              sizes
              srcSetWebp
            }
          }
        }
      }
    }
  }
`;
